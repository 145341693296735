div{-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */}
nav{
  position:fixed;
  width:100%;
  top:0;
  left:0;
  z-index: 99;
}

header{
  padding-top:20px;
  width:100%;
  height: 95vh;
  background-image: url(https://svajhart-pub-photos.s3.us-east-2.amazonaws.com/galaxy.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: bottom;
  background-attachment: fixed;
}